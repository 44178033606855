import { ExternalLink } from "lucide-react";
import Link from "next/link";
import { HELP_CENTER_SUBMIT_REQUEST } from "~/constants/help-articles";
import MailToSupport from "../common/mail-to-support";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";

const faqs = [
  {
    question: "What is EasySLR, and how do reviewers use it?",
    answer:
      "EasySLR is a web-based platform that streamlines the systematic literature review process. There's no need to install an app, as it can be accessed directly through a web browser. Reviewers use EasySLR to efficiently screen titles, abstracts, and full-text articles, extract relevant data, and manage the entire review workflow.",
  },
  {
    question: "How is AI used in EasySLR?",
    answer:
      "AI in EasySLR serves as a copilot to assist during the screening and data extraction processes. It can also be incorporated as a second reviewer alongside human reviewers.",
  },
  {
    question: "How accurate is the AI?",
    answer:
      "We have observed a recall of around 94% and have published our results at ISPOR continuously. Our AI has reviewed more than 25,000 studies so far, with accuracy improving from 84%. We aim to further enhance its performance as we launch our own proprietary models with different reasoning abilities compared to standard language models.",
  },
  {
    question: "Is the study data private and secure?",
    answer:
      "Yes, rigorous encryption protocols and strict privacy standards are implemented to safeguard your information. EasySLR is SOC2 and ISO27001 compliant, ensuring the highest standards of data privacy and security.",
  },
  // {
  //   question: "What is the pricing?",
  //   answer: (
  //     <div>
  //       <div>
  //         For pricing information, please contact our sales team, who will be
  //         happy to assist you and provide details on our subscription plans.
  //         <Dialog>
  //           <DialogTrigger asChild>
  //             <span className="ml-1 inline-flex w-fit cursor-pointer items-center gap-2 underline underline-offset-2">
  //               Contact our sales team.
  //             </span>
  //           </DialogTrigger>
  //           <DialogContent className="sm:max-w-2xl">
  //             <ContactUs />
  //           </DialogContent>
  //         </Dialog>
  //       </div>
  //       <p>
  //         Our subscription plans start at $125 per subscriber per month. AI
  //         credits are an additional cost based on usage.{" "}
  //       </p>
  //     </div>
  //   ),
  // },
  {
    question: "What are AI credits?",

    answer: (
      <>
        <p>
          AI credits are used to access AI-assisted features in EasySLR. They
          are consumed based on the volume of articles processed using AI
          functionalities. AI credits can be used during Title/Abstract and
          Full-text screening.
        </p>
        <p>
          Example: The cost is 10 cents per article for Title/Abstract screening
          and $1 per article for Full-text screening with PICOS extraction.
        </p>
      </>
    ),
  },
  {
    question: "Can I import my previous SLR into EasySLR?",
    answer:
      "Yes, EasySLR supports importing your previous SLR studies into the platform using Excel and Endnote formats. This feature is available for users on our Enterprise plan, allowing you to seamlessly integrate your existing systematic review data into EasySLR.",
  },
  {
    question: "How do I connect with EasySLR?",
    defaultOpen: true,
    answer: (
      <>
        <p className="">
          You can connect with our support team or inquire about sales by
          submitting a query through this link :{" "}
          <Link
            href={HELP_CENTER_SUBMIT_REQUEST}
            className="inline-flex gap-2 underline underline-offset-2 hover:bg-background"
            target="_blank"
          >
            {HELP_CENTER_SUBMIT_REQUEST}{" "}
            <ExternalLink className="mt-[6px] h-4 w-4" />
          </Link>
        </p>
        <p className="">
          You can also reach out to us by email at :{" "}
          <MailToSupport className="underline-offset-2">
            <ExternalLink className="mt-0.5 h-4 w-4" />
          </MailToSupport>
        </p>
      </>
    ),
  },
];

export default function Faqs() {
  return (
    <div id="faqs" className="">
      <div className="relative mx-auto max-w-8xl px-6 py-24 sm:pt-8 lg:px-8 lg:pb-44">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="mt-4 text-4xl font-bold leading-10 tracking-tight">
              <p>Got Questions?</p>
              <p>We&apos;ve Got Answers!</p>
            </h2>
            <p className="mt-4 text-base leading-7">
              Can&apos;t find the answer you&apos;re looking for? Reach out to
              our{" "}
              <Link
                target="_blank"
                href={HELP_CENTER_SUBMIT_REQUEST}
                className="font-semibold"
              >
                customer support
              </Link>{" "}
              team.
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <div className="flex flex-col gap-y-0">
              {faqs.map((faq) => (
                <Accordion
                  key={faq.question}
                  className="w-full"
                  type="single"
                  defaultValue={faq.defaultOpen ? faq.question : ""}
                  collapsible
                >
                  <AccordionItem
                    key={faq.question}
                    value={faq.question}
                    className="w-full border-b-0"

                    // defaultChecked={faq.defaultOpen}
                  >
                    <AccordionTrigger className="text-left text-sm">
                      <div className="text-lg font-semibold leading-7">
                        {faq.question}
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="mt-2 text-base leading-7">
                        {faq.answer}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
