import Image from "next/image";
import React from "react";
import { useRef } from "react";
import useElementPosition from "~/hooks/use-element-position";
import useScreenSize from "~/hooks/use-screensize";
import useScrollPosition from "~/hooks/use-scroll-position";
import { cn } from "~/lib/utils";

const imgCss =
  "md:-ml-4 max-h-[350px] rounded-t-xl border-gray-200 transition-transform duration-300 group-hover:scale-110 group-hover:border-gray-300";

const featureSectionConfig = [
  {
    id: 1,
    title: "Title Abstract Screening Made Easy",
    subtitle:
      "Review duplicates and screen studies efficiently with AI suggestions",
    content: (
      <div>
        <Image
          src={"/common/TiAb2.svg"}
          width={1200}
          height={800}
          className={cn(imgCss)}
          alt="logo.png"
        />
      </div>
    ),
  },
  {
    id: 2,
    title: "Full-Text Screening with AI-Enabled PICOS",
    subtitle: `Streamline your full-text screening process using AI-powered PICOS extraction`,
    content: (
      <div>
        <Image
          src={"/common/FT2.svg"}
          width={1100}
          height={800}
          className={imgCss}
          alt="logo.png"
        />
      </div>
    ),
  },
  {
    id: 3,
    title: "Automated Data Extraction",
    subtitle:
      "Automatically extract key data from studies and link it to highlighted sources for easy verification with our AI-powered tool.",
    content: (
      <div>
        <Image
          src={"/common/DE2.svg"}
          width={1200}
          height={800}
          className={imgCss}
          alt="logo.png"
        />
      </div>
    ),
  },
];

const Feature = ({
  id,
  title,
  subtitle,
  content,
  scrollPos,
  animationHeight,
}: {
  id: number;
  title: string;
  subtitle: string;
  content: string | JSX.Element;
  scrollPos: number;
  animationHeight: number;
}) => {
  // const containerRef = useRef<HTMLDivElement>(null);
  // const { y: elementYCoord } = useElementPosition(containerRef);
  // const { height: screenHeight } = useScreenSize();

  const sectionHeight = 500;
  // const startAnimationFrom = elementYCoord - screenHeight / 1.5;

  // const elHeight =
  //   scrollPos > startAnimationFrom
  //     ? Math.min(scrollPos - startAnimationFrom, sectionHeight)
  //     : 0;
  const elHeight = animationHeight;

  return (
    <div
      // ref={containerRef}
      className={`relative grid grid-cols-[14px_1fr] items-start gap-x-6 sm:grid-cols-[60px_1fr] sm:gap-x-4 lg:grid-cols-[200px_1fr] lg:gap-x-12`}
      style={{ height: sectionHeight }}
    >
      <div className="relative h-full max-md:pl-3">
        <span
          className={cn(
            "border-subtle absolute top-[3px] z-10 grid h-6 w-6 -translate-x-3 scale-100 transform place-items-center rounded-full border text-xs font-medium shadow-[0px_0px_0px_3px_#DBDFE6] duration-300 md:left-1/2",
            elHeight > 0
              ? "bg-black text-white dark:bg-white dark:text-black"
              : "",
            elHeight > 0 && elHeight < 40
              ? "scale-[1.2] transform transition-transform"
              : "transition-transform",
          )}
        >
          {id}
        </span>
        <div
          className={cn(
            "relative flex h-full items-start overflow-clip md:justify-center",
            elHeight > 0 ? "" : "hidden",
          )}
        >
          <div className="relative">
            <div
              className={cn(
                "w-[1px]",
                // "bg-gradient-to-b from-black to-transparent dark:from-white",
                "bg-black dark:bg-white",
                "transition-none duration-0",
              )}
              style={{ height: elHeight }}
            />
            {/* <div className="absolute -bottom-10 h-10 w-[4px] bg-gradient-to-b from-black to-transparent"></div> */}
          </div>
        </div>
      </div>
      <div className="max-w-xl">
        <h3 className="mb-2 text-2xl font-medium leading-none">{title}</h3>
        <p
          className={cn(
            "mb-6 leading-8 md:text-lg",
            // "text-slate-700 dark:text-slate-300",
            "font-light leading-7 text-gray-500 dark:text-slate-300",
          )}
        >
          {subtitle}
        </p>
        {/* <Card className="group relative backdrop-blur-lg duration-300 hover:border-slate-300 dark:bg-white/10 dark:hover:border-slate-400">
          <CardContent className="flex items-center justify-center overflow-hidden p-4 pb-0 sm:p-8 sm:pb-0">
            {content}
          </CardContent>
        </Card> */}
        <div
          className={cn(
            "transform transition-transform duration-300",
            elHeight > 0 && elHeight < sectionHeight ? "scale-105" : "",
          )}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

// const CardsWrapper = () => {
//   const scrollPos = useScrollPosition();
//   return (
//     <>
//       {featureSectionConfig.map((feature) => {
//         return <Feature key={feature.id} {...feature} scrollPos={scrollPos} />;
//       })}
//     </>
//   );
// };

// const CardsWrapper: React.FC<{ scrollPos: number }> = ({ scrollPos }) => {
//   return (
//     <>
//       {featureSectionConfig.map((feature) => (
//         <Feature key={feature.id} {...feature} scrollPos={scrollPos} />
//       ))}
//     </>
//   );
// };

const CardsWrapper: React.FC = () => {
  const [scrollPos, setScrollPos] = React.useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [animationHeights, setAnimationHeights] = React.useState<number[]>([]);

  React.useEffect(() => {
    const handleScroll = () => {
      const newScrollPos = window.scrollY;
      window.requestAnimationFrame(() => {
        if (containerRef.current) {
          const containerTop =
            containerRef.current.getBoundingClientRect().top + window.scrollY;
          const screenHeight = window.innerHeight;
          const newAnimationHeights = featureSectionConfig.map((_, index) => {
            const startAnimationFrom =
              containerTop + index * 500 - screenHeight / 1.5;
            const height = Math.max(
              0,
              Math.min(newScrollPos - startAnimationFrom, 500),
            );
            return height;
          });

          setAnimationHeights(newAnimationHeights);
        }
        setScrollPos(newScrollPos);
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={containerRef} className="relative">
      {featureSectionConfig.map((feature, index) => (
        <Feature
          key={feature.id}
          {...feature}
          scrollPos={scrollPos}
          animationHeight={animationHeights[index] ?? 0}
        />
      ))}
    </div>
  );
};

const GettingStarted = () => {
  // const [scrollPos, setScrollPos] = React.useState(0);

  // React.useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollPos(window.scrollY);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div className="relative pb-12 pt-24 text-foreground lg:pb-16 lg:pt-40">
      {/* <div
        className="absolute inset-x-0 top-1/2 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div> */}
      <div className="mx-auto max-w-8xl px-6 lg:px-8">
        <div className="absolute inset-x-0 top-[calc(300%-13rem)] -z-10 hidden transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] sm:block">
          <svg
            className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        {/* <div
          className="absolute inset-x-0 top-1/2 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
          aria-hidden="true"
        >
          <div
            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div> */}
        {/* <div className="grid grid-cols-1 gap-x-6 lg:grid-cols-2"> */}
        <div className="relative grid justify-start gap-4 lg:grid-cols-[calc(50%-200px)_calc(50%+200px)]">
          <div className="mb-16 space-y-6 lg:mb-8">
            {/* <Link href="#contact-us"> */}
            {/* <Button
              className="self-start rounded-full border border-gray-300"
              variant={"secondary"}
            >
            Get Started 
              AI powered by LLM
            </Button> */}
            {/* </Link> */}
            <div className="">
              <h2 className="mb-2 text-2xl font-medium md:text-3xl lg:mb-6 lg:max-w-md lg:text-5xl">
                Get started with EasySLR
              </h2>
              <p className="text-lg font-light leading-7 text-gray-500 md:text-base lg:max-w-lg dark:text-slate-300">
                {/* EasySLR integrates AI powered by Large Language Model (LLMs)
                research, expert domain knowledge, and a focus on superior user
                experience to streamline and simplify your systematic review
                process */}
                {/* Backed by Large Language Model (LLMs) research, expert domain
                knowledge, and a focus on superior user experience simplify your
                systematic review process. */}
                {/* Powered by Large Language Model research, EasySLR enhances your
                systematic review process with a superior user experience. */}
                With EasySLR, you can automate the most time-consuming parts of
                the process and focus on what you do best. We’ll handle the
                rest.
              </p>
            </div>
          </div>
          <div className="">
            <CardsWrapper />
            {/* <CardsWrapper scrollPos={scrollPos} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
