import { CheckIcon } from "lucide-react";
import { cn } from "~/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { useState } from "react";
import { Button } from "../ui/button";
import { LeadCreateForm } from "../forms/lead";
import Link from "next/link";

const tiers = [
  {
    id: "tier-trial",
    name: "Free Trial",
    priceLabel: <>$0</>,
    description: "Get started for free and see how we can help your business.",
    features: [
      "Upto 1 project",
      "Upto 10 users",
      "Email support",
      "Chat support",
      "All features included",
    ],
    cta: (
      <Button asChild variant="default" className="w-full">
        <Link href="/auth/signup">Get started today</Link>
      </Button>
    ),
  },
  {
    id: "tier-team",
    name: "Professional",
    priceLabel: <>$125</>,
    isPerSeatPerMonth: true,
    description:
      // "A plan that scales with your rapidly growing business. Get access to all features and support.",
      "Everything you need to grow your business.",
    features: [
      "Upto 1,000 projects",
      "Upto 100 users",
      "Email support",
      "Chat support",
      "All features included",
    ],
    cta: (
      <Button asChild variant="default" className="w-full">
        <Link href="/auth/signup">Get started today</Link>
      </Button>
    ),
  },
  {
    id: "tier-enterprise",
    name: "Enterprise",
    priceLabel: <>Custom</>,
    description: "A plan personalized as per your needs.",
    features: [
      "Dynamic pricing",
      "Priority email support",
      "Priority chat support",
      "All features included",
    ],
    cta: (
      <LeadCreateDialog triggerBtnClassName="relative flex w-full items-center gap-2" />
    ),
  },
];

export default function Pricing() {
  return (
    <div
      id="pricing"
      className="relative isolate px-6 py-24 text-foreground sm:py-44"
    >
      <div
        className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
        aria-hidden="true"
      >
        <svg className="mx-auto w-[72.1875rem]" viewBox="0 0 1155 678">
          <path
            fill="url(#083c110e-60c8-42bb-a9db-29bba74cc569)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="083c110e-60c8-42bb-a9db-29bba74cc569"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        {/* <h2 className="text-accented text-base font-semibold leading-7">
          Pricing
        </h2> */}
        <p className="mt-9 text-4xl font-bold tracking-tight">
          Pricing that scales with your business
        </p>
      </div>
      <p className="mx-auto my-6 max-w-3xl text-center text-lg leading-8 text-muted-foreground">
        {/* Our transparent pricing model makes it easy to understand what
        you&apos;re paying for and why. For discounted pricing for long-term
        subscriptions, contact us. */}
        We offer a range of pricing plans to suit your business needs. For
        discounted pricing for <br />
        long-term subscriptions, contact us.
      </p>

      <div className="pb-8 pt-4">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="flow-root">
            <div
              className={cn(
                "isolate mx-auto grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-slate-200 rounded-lg border bg-background/20 px-6 pb-12 lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 lg:py-12 xl:-mx-4",
              )}
            >
              {tiers.map((tier) => (
                <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                  <h3 className="flex items-center gap-3 text-base font-semibold leading-7 text-primary">
                    <span className="">{tier.name}</span>
                  </h3>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-5xl font-bold tracking-tight text-primary">
                      {tier.priceLabel}
                    </span>
                    {tier.isPerSeatPerMonth && (
                      <span className="whitespace-nowrap text-sm font-semibold leading-6 text-muted-foreground">
                        / seat / month
                      </span>
                    )}
                  </p>
                  <div className="mt-8">{tier.cta}</div>
                  <p className="mt-8 min-h-12 text-sm font-semibold leading-6 text-muted-foreground">
                    {tier.description}
                  </p>
                  <ul
                    role="list"
                    className="mt-4 space-y-3 text-sm leading-6 text-muted-foreground"
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          aria-hidden="true"
                          className="h-6 w-5 flex-none"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function LeadCreateDialog({
  triggerBtnClassName,
}: {
  triggerBtnClassName?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className={cn("group relative", triggerBtnClassName)}
        >
          Contact sales{" "}
          <span
            aria-hidden="true"
            className="transition-transform duration-300 group-hover:translate-x-2"
          >
            &rarr;
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Get in touch with us</DialogTitle>
          <DialogDescription>
            We&apos;re here to help and answer any question you might have. We
            look forward to hearing from you.
          </DialogDescription>
        </DialogHeader>
        <LeadCreateForm source="PRICING" onSuccess={() => setIsOpen(false)} />
      </DialogContent>
    </Dialog>
  );
}
