import HeadMeta from "~/components/head-meta";
import ResellCta from "~/components/marketing/resell-cta";

// SSR related code starts here
import FrequentlyAskedQuestions from "~/components/marketing/faqs";
import Features from "~/components/marketing/features";
import GettingStarted from "~/components/marketing/getting-started";
import Hero2 from "~/components/marketing/hero2";
import StartYourFreeProject from "~/components/marketing/start-your-free-project";
import Topbar from "~/components/topbar";
import Pricing from "~/components/marketing/pricing";

// export async function getServerSideProps(
//   context: GetServerSidePropsContext<{ orgId: string }>,
// ) {
//   const authResult = await getAuthenticatedSSRHelper({
//     context,
//     shouldRedirect: false,
//   });
//   if ("redirect" in authResult) return authResult;
//   const { ssr, session } = authResult;
//   return {
//     props: {
//       trpcState: ssr.dehydrate(),
//       user: session?.user ?? null,
//     },
//   };
// }
// SSR related code ends here

export default function Home() {
  // props: InferGetServerSidePropsType<typeof getServerSideProps>,

  return (
    <>
      <HeadMeta />
      {/* <Hero {...props} /> */}
      <div className="relative">
        <Topbar showLinks />
        {/* <Hero /> */}
        <Hero2 />
        {/* <Features /> */}
        {/* <StartYourFreeProject /> */}

        <GettingStarted />
        <Features />
        <Pricing />
        <FrequentlyAskedQuestions />
        <StartYourFreeProject />
        {/* <ContactUs /> */}
        <ResellCta />
      </div>
    </>
  );
}
