import Link from "next/link";
import { cn } from "~/lib/utils";

export default function MailToSupport({
  text = "support@easyslr.com",
  children,
  className = "",
}: {
  text?: string;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <Link
      href="mailto:support@easyslr.com"
      className={cn(
        "inline-flex flex-wrap items-center gap-2 underline underline-offset-4",
        className,
      )}
    >
      {text} {children}
    </Link>
  );
}
