import Image from "next/image";
import useScreenSize from "~/hooks/use-screensize";
import { cn } from "~/lib/utils";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import ContactUs from "./contact-us";
import Link from "next/link";

const StartYourFreeProject = () => {
  const { width: screenWidth } = useScreenSize();
  const smallScreen = screenWidth < 500;

  return (
    <div className="relative px-0 md:p-12 md:px-0">
      <div
        className="absolute inset-x-0 right-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <Card
        className={cn(
          "rounded-none backdrop-blur-lg duration-300",
          "border-none shadow-none",
          "",
          "bg-white/30 dark:bg-white/10",
        )}
      >
        <CardContent className="group relative overflow-hidden p-6 lg:min-h-[500px]">
          <div className="pointer-events-none absolute right-0 top-0 h-1/3 w-1/3 bg-gradient-to-t from-white/0 via-white/0 to-white/100 dark:hidden"></div>

          <div
            className={cn(
              "absolute bottom-0 right-5 -z-40",
              smallScreen ? "" : "hidden",
            )}
          >
            <Image
              src={"/common/FT3.svg"}
              width={300}
              height={300}
              alt="flow.png"
              className="w-4/5"
              style={{
                translate: `60% 20%`,
              }}
            />
          </div>
          <div
            className={cn(
              "absolute -z-10",
              "md:-right-60",
              "lg:-right-60",
              "animate-infscroll",
            )}
            style={{
              translate: `-0% -10%`,
            }}
          >
            <div
              className={cn("flex items-center", smallScreen ? "hidden" : "")}
            >
              <Image
                src={"/common/OverallFlow.svg"}
                width={2000}
                height={2000}
                alt="flow.png"
              />
              <Image
                style={{
                  translate: `-10% -82%`,
                }}
                src={"/common/OverallFlow.svg"}
                width={2000}
                height={2000}
                alt="flow.png"
              />
              <Image
                style={{
                  translate: `-20% -162%`,
                }}
                src={"/common/OverallFlow.svg"}
                width={2000}
                height={2000}
                alt="flow.png"
              />
            </div>
          </div>

          <div className="md:pl-[5%]">
            <h2 className="text-3xl leading-[1.1] lg:text-[5vw] ">
              Claim your<br /> <span className="italic">free project</span> now
            </h2>
            <div className="mt-6">
              <Dialog>
                <DialogTrigger asChild>
                  <Button className="md:[5vw] group transition-transform duration-300 hover:translate-x-2 hover:scale-105 md:text-[2vw] lg:py-6 lg:text-3xl">
                    <Link href="/auth/signup" passHref>
                      Get Started{" "}
                      <span
                        aria-hidden="true"
                        className="ml-2 duration-150 group-hover:translate-x-2"
                      >
                        →
                      </span>{" "}
                    </Link>
                  </Button>
                </DialogTrigger>
                {/* <DialogContent className="sm:max-w-2xl">
                  <ContactUs />
                </DialogContent> */}
              </Dialog>
            </div>
            <div>
              <p className="mt-1 text-xs dark:text-slate-300 sm:ml-1">
                No credit card required
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default StartYourFreeProject;
